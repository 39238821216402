<template>
    <div class="vendorIntroEdit">
        <div class="form">
            <div class="title">
                <h3>Edit Introduction </h3>
                <p>Please fill in this information to let visitors know your block !</p>
                <img class="btn" @click="closeIntro" src="@/assets/img/icon/cross_black.svg" alt="">
            </div>
            <div class="container">
                <div class="column">
                    <div class="themeWrap">
                        <h3><span class="mark">*</span>Theme</h3>
                        <p>Select 1 Theme</p>
                        <el-select v-model="value" placeholder="Please choose one theme" class="themeSelect" popper-class="vendorIntro">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="picUploadWrap">
                        <div class="upload">
                            <div class="uploadModelImg">
                                <label for="upload-model-input" id="upload-model-photo" :style="{backgroundImage: 'url(' + uploadCoverDefault + ')'}"></label>
                                <input type="file" @change="coverImgChange" id="upload-model-input">
                            </div>
                            <div class="txt">
                                <h3><span class="mark">*</span>Cover</h3>
                                <p>Size：500 * 500</p>
                                <p>File format：PNG</p>
                                <button @click="download('cover')">Download Sample</button>
                            </div>
                        </div>
                        <div class="upload">
                            <div class="uploadModelImg">
                                <label for="upload-model-input1" id="upload-model-photo1" :style="{backgroundImage: 'url(' + uploadSignboardDefault + ')'}"></label>
                                <input type="file" @change="signboardImgChange" id="upload-model-input1">
                            </div>
                            <div class="txt">
                                <h3><span class="mark">*</span>Signboard</h3>
                                <p>Size：500 * 500</p>
                                <p>File format：PNG</p>
                                <button @click="download('signboard')">Download Sample</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="tagWrap">
                        <div class="tagTitle">
                            <h3>Tag</h3>
                            <span>{{activeTagNum}}/3</span>
                        </div>
                        <div class="tagContainer">
                            <div class="item btn" :class="{active:item.active}" v-for="(item,index) in tagData" @click="tagActive(index)">{{item.name}}</div>
                        </div>
                    </div> -->
                </div>
                <div class="column">
                    <!-- <div class="briefWrap">
                        <h3>Name</h3>
                        <p>The Name will be displayed on the profile.</p>
                        <p>Please don't write too long !</p>
                        <input type="text" placeholder="Enter your brief" v-model="title">
                    </div> -->
                    <div class="introduction">
                        <h3><span class="mark">*</span>Introduction </h3>
                        <p>The Introduction will be displayed in your exhibition.</p>
                        <p>You can introduce your exhibition in detail !</p>
                        <textarea v-model="introduction" name="" id="" cols="30" rows="0" placeholder="Enter the introduction  of your exhibition..."></textarea>
                    </div>
                </div>
            </div>
            <div class="btnWrap">
                <button class="cancel" @click="closeIntro">Cancel</button>
                <button class="submit" @click="updateData">Save</button>
            </div>
        </div>
    </div>
</template>
<script>
import {getExhTag,uploadSellerCover,uploadSellerSignboard,HostUrl,updateSellerExhData,getVendorExh} from "@/api"
import uploadDefault from "@/assets/img/uploadCoverPreview.svg"
import { mapActions,mapGetters } from 'vuex'
import cover from "@/assets/img/CoverSample.jpg"
import signboard from "@/assets/img/SignboardSample.jpg"
export default {
    name:'vendorIntroEdit',
    data() {
        return {
            tagData:[],
            activeTagArray:[],
            coverPreview:'',
            signboardPreview:'',
            cover:new FormData(),
            signboard:new FormData(),
            introduction:'',
            title:'',
            options: [{
                value: '1',
                label: 'Arts & Humanities'
                }, {
                value: '2',
                label: 'Industry'
                }, {
                value: '3',
                label: 'Business'
                }, 
                {
                value: '4',
                label: 'Cultural and Creative'
                },{
                value: '5',
                label: 'Health care'
                },{
                value: '6',
                label: 'Technology'
                },
                {
                value: '7',
                label: 'Tourism'
                },{
                value: '8',
                label: 'Entertainment'
                }
                
            ],
            value: ''
        }
    },
    props:{
        'activeTag':{
            default:[],
        }
    },
    mounted() {
        this.exhInfoInit();
    },
    methods:{
        ...mapActions(["setExhData"]),
        tagActive(index){
            if(this.activeTagArray.length+1<4){
                this.tagData[index].active = !this.tagData[index].active   
            }else{
                this.tagData[index].active = false
            }
            this.activeTagArray = this.tagData.filter(function(item){
                return item.active == true
            })
        },
        closeIntro(){
            this.$emit('closeIntro',false)
            this.getExh()
        },
        getUrlBase64(url) {
            return new Promise(resolve => {
                let canvas = document.createElement('canvas')
                let ctx = canvas.getContext('2d')
                let img = new Image()
                img.crossOrigin = 'Anonymous' //允許跨域
                img.src = url
                img.onload = function() {
                canvas.height = 300
                canvas.width = 300
                ctx.drawImage(img, 0, 0, 300, 300)
                let dataURL = canvas.toDataURL('image/png')
                canvas = null
                resolve(dataURL)
                }
            })
        },
        download(url) {
            let imgUrl
            if(url=='cover'){
                imgUrl = cover
            }else{
                imgUrl = signboard
            }
            this.getUrlBase64(imgUrl).then(base64 => {
                let link = document.createElement('a')
                link.href = base64
                link.download = 'qrCode.png'
                link.click()
            })
        },
        async getTag(){
            let vm = this
            let res = await getExhTag()
            vm.options.length = 0 
            vm.activeTagArray.length = 0 
            res.data.data.forEach((item,index)=>{
                vm.options.push({label:item.name,value:item.key})
                vm.activeTag.forEach((item1,index1)=>{
                    if(item.key==item1.key){                        
                        vm.value = item1.key;
                        return 
                    }
                })
            })
        },
        async signboardImgChange(e){
            const data = URL.createObjectURL(e.target.files[0]);
            this.signboard.append('image', e.target.files[0]) //放進上傳的檔案
            let res = await uploadSellerSignboard(this.signboard)
            this.signboardPreview = res.data.data.url
        },
        async coverImgChange(e){
            const data = URL.createObjectURL(e.target.files[0]);
            this.cover.append('image', e.target.files[0]) //放進上傳的檔案
            let res = await uploadSellerCover(this.cover)
            this.coverPreview = res.data.data.url
        },
        async updateData(){
            let tagAarray = []
            tagAarray.push(this.value);
            let param = {
                signboardUrl: this.signboardPreview,
                coverUrl: this.coverPreview,
                title: this.title,
                introduction: this.introduction,
                tags: tagAarray, // JSON string array
                status: this.editingExh.status  // publish or draft
            }
            let res = await updateSellerExhData(param,this.editingExh._id)
            this.$emit('getEditng');
            this.formClear()
            this.closeIntro()
        },
        formClear(){
            this.signboardPreview = ''
            this.coverPreview = ''
            this.title = ''
            this.introduction = ''
            this.activeTagArray.length = 0
        },
        async getExh(){
            let res = await getVendorExh()
            this.setExhData(res.data.data)
        },
        exhInfoInit(){
            this.signboardPreview = this.editingExh.signboardUrl
            this.coverPreview = this.editingExh.coverUrl
            this.introduction = this.editingExh.introduction
            this.title = this.editingExh.title
            this.getTag();
        }
    },
    computed:{
        ...mapGetters(["editingExh","exhData"]),
        uploadCoverDefault(){
            if(this.coverPreview!=''&&this.coverPreview!=undefined){
                return HostUrl+this.coverPreview
            }else{
                return uploadDefault
            }
        },
        uploadSignboardDefault(){
            if(this.signboardPreview!=''&&this.signboardPreview!=undefined){
                return HostUrl+this.signboardPreview
            }else{
                return uploadDefault
            }
        },
    },
    watch:{
        editingExh(newV,oldV){
            this.exhInfoInit();
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
    ::placeholder { /* CSS 3 標準 */
        color:#101010;
    }

    ::-webkit-input-placeholder { /* Chrome, Safari */
        color:#101010;
    }

    :-ms-input-placeholder { /* IE 10+ */
        color:#101010;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        color:#101010;
    }
    .vendorIntroEdit{
        position: fixed;
        background-color:rgba($color: #000000, $alpha: 0.5);
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index: 999;
        .mark{
            color: #FF4CAD;
            font-size: 16px;
            position:absolute;
            left: -11px;
        }
        .form{
            width:696px;
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            padding-bottom: 3%;
            border-radius: 6px;
            overflow: hidden;
            padding-top: 1.5%;
            @include for-size(tablet-landscape-only) {
                width: 900px;
            }
        }
        .form .title{
            position: relative;
            
            h3{
                font-size: 20px;
                font-weight: 500;
                line-height: 2.2;
                color:#333333;
                margin: 0;
                @include for-size(tablet-landscape-only) {
                    font-size: 16px;
                }
            }
            p{
                margin-top: 0;
                font-size: 12px;
                margin-bottom: 8%;
                color: #282828;
            }
            img{
                position: absolute;
                right: 4px;
                top: -20px;
            }
        }
        .column{
            display: flex;
            width:50%;
            padding-left: 6.3%;
            flex-wrap: wrap;
            @include for-size(tablet-landscape-only) {
                padding-left: 3.3%;
            }
            &:nth-of-type(2){
                padding-right: 6.3%;
                display: block;
                padding-left: 0;
                @include for-size(tablet-landscape-only) {
                    padding-right: 3.3%;
                }
            }
        }
        .picUploadWrap{
            display: flex;
            width: 100%;
            margin-bottom: 10%;
            flex-wrap: wrap;
            @include for-size(tablet-landscape-only) {
                margin-bottom: -54px;
            }
            .upload{
                //width: 50%;
                display:flex;
                text-align: left;
                width: 90%;
                flex-direction: row-reverse;
                justify-content: flex-end;
                @include for-size(tablet-landscape-only) {
                    width: 48.1%;
                }
                .uploadModelImg{
                    width: 47%;
                    @include for-size(tablet-landscape-only) {
                        width: 44%;
                    }
                    #upload-model-photo,#upload-model-photo1{
                        display:block;
                        width:100%;
                        background-size: 100%;
                        padding-bottom: 100%;
                        cursor: pointer;
                        background-position: center;
                    }
                    #upload-model-input,#upload-model-input1{
                        opacity: 0;
                        position: absolute;
                        z-index: -1;
                    }
                }
                .txt{
                    width: 45%;
                    text-align: left;
                    padding-top: 8px;
                    @include for-size(tablet-landscape-only) {
                        width: 52%;
                    }
                    h3{
                        font-size: 16px;
                        margin: 0 0 10px;
                        position:relative;
                        @include for-size(tablet-landscape-only) {
                            font-size: 16px;
                        }
                    }
                    p{
                        font-size: 12px;
                        margin: 0.5em 0;
                    }
                    button{
                        font-size: 12px;
                        padding: 0;
                        border: none;
                        background-color: transparent;
                        color:$purple;
                        text-decoration-line: underline;
                        &:hover{
                            color:$purple;
                        }
                    }
                }
            }
        }
        .themeWrap{
            width: 79%;
            margin-bottom: 45px;
            h3{
                font-size: 16px;
                margin:0;
                text-align: left;
                margin: 0 0 10px;
                position:relative;
            }
            p{
                font-size: 12px;
                margin:0;
                text-align: left;
                margin: 0 0 10px;
            }
        }
        .tagWrap{
            width: 94.7%;
            .tagTitle{
                position: relative;
                h3{
                    font-size: 18px;
                    text-align: left;
                    @include for-size(tablet-landscape-only) {
                        font-size: 16px;
                    }
                }
                span{
                    font-size: 12px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
            .tagContainer{
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                .item{
                    //width:22%;
                    padding: 0 5%;
                    margin-bottom:22px;
                    background-color: $lightPurple;
                    color:#8B9CB3;
                    border:1px solid #8B9CB3;
                    font-size: 14px;
                    margin-right: 4%;
                    line-height: 2.2;
                    border-radius: 5px;
                    align-items: center;
                    justify-content: center;
                    display:flex;
                    // &:nth-of-type(4n){
                    //     margin-right: 0;
                    // }
                    &.active{
                        border:1px solid $purple;
                        color:$purple;
                    }
                }
            }
        }
        .container{
            display: flex;
        }
        .briefWrap,.introduction{
            text-align: left;
            width: 100%;
            h3{
                font-size: 16px;
                margin: 0 0 10px;
                position:relative;
                @include for-size(tablet-landscape-only) {
                    font-size: 16px;
                }
            }
            p{
                font-size: 12px;
                margin: 0.5em 0;
                &:nth-of-type(2){
                    margin-bottom: 14px;
                }
            }
        }
        .briefWrap{
            margin-bottom: 16.3%;
            margin-bottom: 15%;
            @include for-size(tablet-landscape-only) {
                margin-bottom: 50px;
            }
            input{
                font-size: 12px;
                width: 100%;
                line-height: 2;
                padding-left: 5px;
                &:focus{
                    border-color:$purple;
                }
            }
        }
        .introduction{
            textarea{
                width: 100%;
                padding-left: 5px;
                font-size: 12px;
                outline: none;
                resize: none;
                border-radius: 5px;
                padding-top:5px;
                line-height: 1.5;
                padding-bottom: 250px;
                &:focus{
                    border-color:$purple;
                }
                @include for-size(tablet-landscape-only) {
                    padding-bottom: 200px;
                }
            }
        }
        .btnWrap{
            button{
                width:100px;
                font-size:14px;
                line-height: 2;
                border-radius: 6px;
                margin: 0 10px;
                @include blockShadow;
            }
        }
        ::v-deep .el-select{
            text-align: left;
            display: block;
        }
        ::v-deep .el-input--suffix .el-input__inner{
            border-color: #333333;
            height: 28px;
            font-size: 12px;
            border-radius: 2px;
        }
        ::v-deep .el-select .el-input .el-select__caret{
            color: #333333;
            line-height: 28px;
        }
        ::v-deep .el-select-dropdown__item{
            text-align: left !important;
        }
    }

</style>